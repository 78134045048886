import request from '@/utils/request'


export function getSymbols(data) {
  return request({
    url: 'crm/getSpreadSymbols',
    method: 'get',
    params: data,
  })
}



<template>
  <div class="app-container summary-page traderecord-page">
    <eHeader
        ref='header'
        :query="query"
        @clear="clear"
        @toQuery="toQuery"
    />
    <!--stripe-->
    <el-table
        border
        v-loading="loading"
        :data="data"
        size="small"
        :span-method="objectSpanMethod"
        style="width: 100%;"
    >
      <el-table-column prop="symbol" label="symbol" />
      <el-table-column prop="serialNo" label="排名" />
      <el-table-column prop="platform" label="平台"/>
      <el-table-column prop="account" label="账户"/>
      <el-table-column prop="group" label="分组"/>
      <el-table-column prop="commission" label="返佣"/>
      <el-table-column prop="maxSpread" label="MAX"/>
      <el-table-column prop="minSpread" label="MIN"/>
      <el-table-column prop="avgSpread" label="AVG"/>
    </el-table>
    <!--分页组件-->
    <public-pagination
        :total-page="total"
        :current-page="page"
        @pageChange="pageChange"
        @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/stat/spread/header'
import { initData } from '@/api/data'
export default {
  name:'spread',
  components: { eHeader },
  props:{
  },
  data() {
    return {
      loading: false,
      data: [],
      page: 0,
      size: 10,
      total: 0,
      url: '',
      params: {},
      query: {
        periodType: 1,
      },
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    async init() {
      if (!await this.beforeInit()) {
        return
      }
      return new Promise((resolve, reject) => {
        this.loading = true
        initData(this.url, this.params).then(res => {
          this.total = +res.totalElements;
          // 需要处理数据
          let listData = [];
          for (let i = 0; i < res.content.length; i++) {
            for (let j = 0; j < res.content[i].data.length; j++) {
              res.content[i].data[j].symbol = res.content[i].symbol;
              listData.push(res.content[i].data[j]);
            }
          }
          this.data = listData;
          if(this.statistics){
            this.statistics = res.statistics;
          }
          setTimeout(() => {
            this.loading = false
          }, this.time)
          resolve(res)
        }).catch(err => {
          this.loading = false
          reject(err)
        })
      })
    },
    beforeInit() {
      this.url = '/crm/getSpreadAnalysis'
      this.params = {
        page: this.page,
        size: this.size,
        ...this.query,
      }
      return true
    },
    clear(){
      this.query = {};
      this.toQuery();
    },
    pageChange(e) {
      this.page = e - 1;
      this.init();
    },
    sizeChange(e) {
      this.page = 0
      this.size = e;
      this.init();
    },
    toQuery(){
      this.page = 0;
      this.init();
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 合并相同列数据的行
      // if(columnIndex == 0 && rowIndex !== this.data.length - 1){
      //   const _row = this.data[rowIndex]
      //   const _col = _row > 0 ? 1 : 0
      //   return {
      //     rowspan: 2,
      //   }
      // }

    }
  }
}
</script>

<style lang="less">
.spread {

}
</style>

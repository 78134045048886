<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
        placeholder="请选择"
      class="filter-item"
      v-model="query.periodType"
    >
      <el-option
        v-for="(item,index) in timeLists"
        :key="index"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-select
      v-model="query.symbol"
      placeholder="品种"
      style="width: 150px;"
      class="filter-item"
      filterable
      @keyup.enter.native="toQuery"
    >
      <el-option
        v-for="(item,index) in symbolList"
        :key="index"
        :label="item"
        :value="item"
      ></el-option>
    </el-select>
    <el-button
        v-if="checkPermission(['ADMIN','SPREAD_ANALYSIS_SELECT'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-search"
        @click="toQuery"
    >搜索</el-button>
    <el-button
            type="primary"
            class="filter-item"
            size="mini"
            icon="el-icon-close"
            @click="clear"
    >清空</el-button>
  </div>
</template>

<script>
import { getSymbols } from '@/api/stat/spread'
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true,
      default: {
        periodType: 1
      }
    }
  },
  created() {
    this.getSymbolsList();
  },
  data(){
    return {
      loading:false,
      timeLists: [
        {label: '近一个小时',value: 1},
        {label: '近十二个小时',value: 2},
        {label: '近一天',value: 3},
        {label: '近一周',value: 4},
        {label: '近一月',value: 5},
        {label: '全部',value: 0},
      ],
      symbolList: [],
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    },
    clear() {
      this.$emit('clear');
    },
    getSymbolsList(){
      getSymbols().then((res) => {
        this.symbolList = res;
      })
    }
  }
}
</script>
